import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
// import AIChat from "./AIChat";
import { useLocation } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import toast, { Toaster } from "react-hot-toast";
import fileContext from "../context/fileContext";
import { api } from "../utils/axios-instance";
import { auth } from "../helper/firebaseClient";
import { IconButton } from "@mui/material";
import HistoryIcon from "@mui/icons-material/History";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import CachedIcon from "@mui/icons-material/Cached";
import OpenWithGoogle from "./OpenWithGoogle";
import LoadingButton from "@mui/lab/LoadingButton";
import useAuth from "../stores/authStore";
import handleTextExtraction from "../helper/fileExtraction";
// images from public folder
const PDF = `${process.env.PUBLIC_URL}/assets/pdf.webp`;
const Doc = `${process.env.PUBLIC_URL}/assets/doc.webp`;
const Image = `${process.env.PUBLIC_URL}/assets/image.webp`;
const Ppt = `${process.env.PUBLIC_URL}/assets/ppt.webp`;
const Txt = `${process.env.PUBLIC_URL}/assets/txt.webp`;
const Video = `${process.env.PUBLIC_URL}/assets/video.webp`;

// Define SVG icons for different file types
const fileIcons = {
  "image/png": Image,
  "image/jpeg": Image,
  "application/pdf": PDF,
  "application/msword": Doc,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    Doc,
  "application/vnd.ms-powerpoint": Ppt,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    Ppt,
  "text/plain": Txt,
  "video/mp4": Video,
  // Add more as needed
};

const FileDetails = ({
  fileInfo,
  sharedFileInfo,
  closeDrawer,
  preUrl,
  signedUrl,
  saving,
  setPreUrl,
  securityDetails,
  saveVersion,
}) => {
  const context = useContext(fileContext);
  // const { removeFile } = context;
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const profileData = useAuth((state) => state.profileData);
  const userAccess =
    securityDetails?.selective_access &&
    securityDetails.selective_access[profileData.id].access;
  let downloadEnabled = true;

  if (userAccess) {
    downloadEnabled =
      securityDetails?.download_enabled && userAccess.includes("download");
  }

  // Function to format the date
  const formatDate = (dateString) => {
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
    };
    const formattedDate = new Date(dateString).toLocaleString("en-IN", options);
    return formattedDate;
  };

  const handleBackButtonClick = async () => {
    console.log(fileInfo);
    if (location.pathname === "/chat") {
      closeDrawer();
      context.setOpenInChat(false);
    } else closeDrawer();

    // Update the file document on OpenSearch
    const res = await handleTextExtraction(
      context.currFile,
      fileInfo?.id,
      fileInfo?.name,
      fileInfo?.color,
      profileData,
      true
    );
  };

  const downloadAlert = async (fileId) => {
    const token = auth.currentUser && (await auth.currentUser.getIdToken());
    try {
      if (fileId) {
        const res = await api.post(`/file/logEvent/${fileId}?event=download`, {
          event: "download",
          idToken: token,
        });
        console.log("download log :", res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIconByMimeType = (mimeType) => {
    // Use the fileIcons object to get the appropriate SVG icon
    return fileIcons[mimeType] || PDF; // Default to PDF icon if not found
  };

  const handleDownload = async () => {
    try {
      // Create a temporary anchor element
      const downloadLink = document.createElement("a");
      downloadLink.href = preUrl;
      downloadLink.download = fileInfo.name.split("_TS=")[0];

      // Append the anchor element to the document and click it to trigger the download
      document.body.appendChild(downloadLink);
      downloadLink.click();

      // Remove the anchor element from the document
      document.body.removeChild(downloadLink);

      console.log("Download success");
      await downloadAlert(fileInfo.id);
    } catch (error) {
      console.error(
        "Error occurred while downloading the file:",
        error.message
      );
    }
  };

  console.log("fileInfo", fileInfo);

  // const handleDelete = async () => {
  //   let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));

  //   // Check if the user is the owner of the file
  //   // console.log(fileName);

  //   if (profileData.id === fileInfo.owner) {
  //     try {
  //       const storage = getStorage();
  //       const fileRef = ref(storage, `files/${profileData.org}/${fileInfo.id}`);

  //       await deleteObject(fileRef);
  //       console.log("Delete success");

  //       const res = await api.delete(`/file/delete-file/${fileInfo.id}/`);

  //       removeFile(fileInfo.id);
  //       // if (deptName) updateDepartmentFiles(deptName);

  //       toast.success("File deleted successfully.");
  //       handleBackButtonClick();
  //     } catch (error) {
  //       toast.error("Error occurred while deleting the file");
  //       console.error("Error occurred while deleting the file:", error.message);
  //     }
  //   } else {
  //     // Display Snackbar message if the user is not the owner of the file

  //     toast.error("You are not the owner of this file.");
  //   }
  // };

  const handleRecycleBin = async () => {
    console.log("fileInfo : ", fileInfo);
    let profileData = JSON.parse(secureLocalStorage.getItem("profileData"));
    const token = await auth.currentUser.getIdToken();

    console.log("Token: ", token);

    if (profileData.id === fileInfo.owner) {
      try {
        const deleted = 1;

        // Create the payload for the backend
        const recycleData = {
          fileId: fileInfo.id,
          fileName: fileInfo.name,
          owner: fileInfo.owner,
          deleted: deleted,
          idToken: token,
          size: fileInfo.size,
          mimeType: fileInfo.mimeType,
          profilePic: fileInfo.ownerProfileUrl,
          downloadUrl: fileInfo.downloadUrl,
          currVersion: fileInfo?.currVersion,
          versions: fileInfo?.versions
        };

        console.log(
          "File before being moved to Recycle Bin via backend:",
          recycleData
        );

        // Make the API POST request to your Django backend
        const res = await api.post(
          `/file/recycle-file/${fileInfo.id}/`,
          recycleData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(
          "File after being moved to Recycle Bin via backend:",
          res.data
        );

        // Update the file list in context/state to remove the deleted file
        // removeFile(id); // Assuming removeFile updates the list in context

        // Display success message
        // setSnackbarSeverity("success");
        // setSnackbarMessage("File successfully moved to Recycle Bin.");
        // setSnackbarOpen(true);

        // Delay closing the dialog to ensure Snackbar appears
        setTimeout(() => {
          // closeDialog();
          window.location.reload();
        }, 3000); // Adjust the delay as needed (3 seconds in this case)
      } catch (error) {
        console.error("Error occurred while deleting the file:", error.message);
        // setSnackbarSeverity("error");
        // setSnackbarMessage("Error deleting the file.");
        // setSnackbarOpen(true);
      }
    } else {
      // setSnackbarSeverity("error");
      // setSnackbarMessage("You are not the owner of the file.");
      // setSnackbarOpen(true);
      setTimeout(() => {
        // closeDialog();
        window.location.reload();
      }, 3000);
    }
  };

  useEffect(() => {
    if (fileInfo.drive_file_id && fileInfo.owner === profileData.id) {
      setOpen(true);
    }
  }, [fileInfo.drive_file_id, fileInfo.owner, profileData.id]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleFileSync = async () => {
    setSyncing(true);
    const token = await auth.currentUser.getIdToken();
    const res = await api.post(`/file/sync-file/`, {
      idToken: token,
      fileId: fileInfo.id,
    });

    if (res.status === 200) {
      const { download_url } = res.data;

      setPreUrl(download_url);

      // Update cache
      const CACHE_NAME = "blob-cache";
      const fileIdentifier = `${fileInfo.id}-${token}`;
      const fileData = await fetch(download_url);
      const file = await fileData.blob();

      const cache = await caches.open(CACHE_NAME);
      const responseToCache = new Response(file, {
        headers: {
          "Content-Type": fileInfo.mimetype,
          "sw-cache-date": new Date().toISOString(),
        },
      });
      await cache.put(fileIdentifier, responseToCache);
      console.log("Cache updated for file:", fileIdentifier);

      toast.success("File synced successfully.");
      setOpen(false);
      setSyncing(false);
    } else {
      console.log(res.data);
      toast.error("Error occurred while syncing the file.");
      setSyncing(false);
    }
  };

  const action = (
    <React.Fragment>
      <LoadingButton
        sx={{
          borderRadius: "20px",
          textTransform: "none",
          marginRight: 1,
        }}
        loading={syncing}
        variant="contained"
        size="small"
        onClick={handleFileSync}
      >
        Sync
      </LoadingButton>

      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    console.log(fileInfo),
    (
      <div className="bg-[#FAFAFA] text-white py-2 px-4 flex justify-between">
        {/* <button onClick={handleBackButtonClick}>Back</button> */}
        <Snackbar
          open={open}
          onClose={handleClose}
          message="Do you want to sync the changes from Drive?"
          action={action}
        />
        <Toaster position="bottom-left" reverseOrder={false} />
        <div className="flex flex-col sm:flex-row items-center justify-between w-full">
          <div className="flex items-center justify-center">
            <span className="flex-shrink-0 ml-auto">
              {/* <img
                src={LeftArrow}
                alt="←"
                onClick={handleBackButtonClick}
                className="cursor-pointer"
              /> */}
              <img
                src={getIconByMimeType(fileInfo.mimetype)}
                alt="File Preview"
                className="cursor-pointer rounded-md h-8 w-8"
                onClick={handleBackButtonClick}
              />
            </span>

            <div className="mx-2 w-auto">
              <div className="flex gap-6 items-center justify-center lg:justify-start w-full">
                <p className="ml-1 text-md text-black font-semibold overflow-hidden text-ellipsis line-clamp-1">
                  {fileInfo?.name?.split("_TS=")[0]}
                </p>
                <p className="text-sm text-gray-400 whitespace-nowrap text-ellipsis">
                  {saving ? (
                    <>
                      <div className="flex items-center gap-2 text-gray-500 font-semibold">
                        <CachedIcon fontSize="inherit" />
                        Saving..
                      </div>
                    </>
                  ) : (
                    <>
                      <Tooltip title={fileInfo.size} arrow>
                        <div className="flex items-center gap-2 text-gray-500 font-semibold hover:bg-gray-200 cursor-pointer px-2 py-1 rounded-md">
                          <CloudDoneIcon fontSize="inherit" />
                          <p className="text-xs">Saved</p>
                        </div>
                      </Tooltip>
                    </>
                  )}
                </p>
              </div>
              <div className="flex gap-2 -ml-1 justify-center lg:justify-start">
                <p
                  onClick={handleBackButtonClick}
                  className="hover:bg-gray-200 cursor-pointer px-2 rounded-md text-sm text-gray-600 whitespace-nowrap overflow-hidden text-ellipsis"
                >
                  Home
                </p>
                <p
                  onClick={handleDownload}
                  className={`hover:bg-gray-200 cursor-pointer px-2 rounded-md text-sm text-gray-600 whitespace-nowrap overflow-hidden text-ellipsis ${downloadEnabled ? "" : "hidden"}`}
                >
                  Download
                </p>

                {/* <p className="text-sm text-gray-600">{fileInfo.size}</p> */}
              </div>
            </div>
          </div>

          <div className="flex flex-row items-center justify-center lg:justify-end">
            {/* <span className="flex flex-col gap-2">
              <p className="text-sm text-gray-400 font-semibold">
                Who has access
              </p>

              {sharedFileInfo?.shared_with?.map((user) => (
            <span key={user.user_id} className="flex flex-row items-center">
              <Tooltip title={user?.email} arrow>
                <Avatar
                  src={user.profilePictureUrl}
                  alt="owner pic"
                  sx={{ width: 20, height: 20, marginRight: 1 }}
                />
              </Tooltip>
              <p className="text-xs text-gray-300 font-semibold">
                {user.first_name} {user.last_name}
              </p>
            </span>
          ))}
            </span> */}

            {/* <img
                onClick={handleDelete}
                src={Trash}
                alt="delete"
                className="cursor-pointer"
              />
              <img
                onClick={handleDownload}
                src={DownloadFile}
                alt="download"
                className="cursor-pointer"
              /> */}
            <button
              className="py-1 px-4 rounded-md border bg-[#1c4ed8] mr-2"
              onClick={saveVersion}
            >
              Save Version
            </button>
            <OpenWithGoogle
              presignedUrl={preUrl}
              fileName={fileInfo?.name?.split("_TS=")[0]}
              mimeType={fileInfo.mimetype}
              firestoreFileId={fileInfo.id}
              driveLink={fileInfo.drive_link}
              driveFileId={fileInfo.drive_file_id}
              owner={fileInfo.owner}
            />
            <Tooltip
              title={`Last modified ${formatDate(fileInfo.lastUpdate)}`}
              arrow
            >
              <IconButton>
                <HistoryIcon className="" />
              </IconButton>
            </Tooltip>
            {/* <IconButton onClick={handleDownload}>
              <DownloadForOfflineIcon />
            </IconButton> */}

            <IconButton
              disabled={userAccess && userAccess.includes("edit")}
              onClick={handleRecycleBin}
            >
              <DeleteForeverIcon />
            </IconButton>
            {/* <AIChat signedUrl={signedUrl} /> */}

            <Tooltip title="Owner" arrow>
              <Avatar
                src={fileInfo.ownerProfileUrl}
                alt="owner pic"
                sx={{ width: 28, height: 28 }}
                className="cursor-pointer ml-2"
              />
            </Tooltip>
          </div>
        </div>
      </div>
    )
  );
};

export default FileDetails;
