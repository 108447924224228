import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import toast from "react-hot-toast";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { auth, storage } from "../../helper/firebaseClient";
import useAuth from "../../stores/authStore";
import fileContext from "../../context/fileContext";
import { io } from "socket.io-client";
import { DocumentEditor, Inject } from "@syncfusion/ej2-react-documenteditor";
import handleTextExtraction from "../../helper/fileExtraction";

import {
  CollaborativeEditingHandler,
  DocumentEditorContainerComponent,
  Toolbar,
  WordExport,
} from "@syncfusion/ej2-react-documenteditor";

import "../../styles/DocEditor_styles.css";
import "../../styles/material.css";

import { registerLicense } from "@syncfusion/ej2-base";
import { generateFileVersionId } from "../../utils/helpers";
import { api } from "../../utils/axios-instance";
registerLicense(
  "ORg4AjUWIQA/Gnt2U1hhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5bdkNiW35ac3VRT2da"
);

const CACHE_NAME = "blob-cache";

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => func.apply(this, args), delay);
  };
};

export default function TextEditor({
  preUrl,
  fileName,
  fileId,
  mimetype,
  setSaving,
  saving,
  securityDetails,
  currVersion,
}) {
  const editorRef = useRef(null);
  const [isEditorReady, setIsEditorReady] = useState(false);
  const [socket, setSocket] = useState(null);
  const [collaborativeEditingHandler, setCollaborativeEditingHandler] =
    useState();
  const profileData = useAuth((state) => state.profileData);
  const context = useContext(fileContext);
  const userAccess = securityDetails?.selective_access[profileData.id].access;

  useEffect(() => {
    const s = io(process.env.REACT_APP_REALTIME_SERVER_URL);

    setSocket(s);

    s.emit("join-room", fileId, profileData.email);

    return () => {
      s.disconnect();
    };
  }, [fileId, profileData.email]);

  useEffect(() => {
    if (editorRef.current && preUrl.trim() !== "") {
      const fetchAndExtractContent = async (wordFileUrl) => {
        try {
          // Fetch the Word file
          const response = await fetch(wordFileUrl);
          if (!response.ok) {
            throw new Error("Failed to fetch the Word file");
          }

          // Convert response to blob
          const blob = await response.blob();
          const file = new File([blob], fileName, {
            type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          });

          context.setCurrFile(file);
          editorRef.current.documentEditor.open(file);
          console.log(userAccess);
          if (userAccess && !userAccess?.includes("edit")) {
            editorRef.current.documentEditor.isReadOnly = true;
          }
        } catch (error) {
          console.error("Error fetching and extracting content:", error);
        }
      };
      fetchAndExtractContent(preUrl);

      function handleKeyBoardShortcuts() {
        editorRef.current.documentEditor.keyDown = function (args) {
          let keyCode = args.event.which || args.event.keyCode;
          let isCtrlKey =
            args.event.ctrlKey || args.event.metaKey
              ? true
              : keyCode === 17
                ? true
                : false;

          if (isCtrlKey && keyCode === 83) {
            //To prevent default save operation, set the isHandled property to true
            args.isHandled = true;

            editorRef.current.documentEditor.save(
              fileName.split(".").slice(0, -1).join("."),
              "Docx"
            );

            args.event.preventDefault();
          }
        };
      }

      handleKeyBoardShortcuts();

      DocumentEditor.Inject(CollaborativeEditingHandler);
      editorRef.current.documentEditor.enableCollaborativeEditing = true;
      console.log(
        "Collaborative editing enabled:",
        editorRef.current.documentEditor.collaborativeEditingHandlerModule
      );
    }
  }, [preUrl, isEditorReady, fileName]);

  useEffect(() => {
    if (socket && editorRef.current && collaborativeEditingHandler) {
      socket.on("receive-changes", (delta) => {
        console.log("Received changes:", delta);
        collaborativeEditingHandler.applyRemoteAction("action", {
          operations: delta,
          connectionId: fileId,
          userId: profileData.email,
          // version: 1,
        });

        // Update cache
        const token = auth.currentUser.getIdToken();
        const fileIdentifier = `${fileId}-${token}`;
        const cache = caches.open(CACHE_NAME);

        cache.then((cache) => {
          cache.match(fileIdentifier).then((response) => {
            const fileVersionId = generateFileVersionId(fileId);
            if (response) {
              const updatedResponse = new Response(response.body, {
                headers: {
                  "Content-Type": mimetype,
                  "sw-cache-date": new Date().toISOString(),
                  "x-file-version-id": fileVersionId,
                },
              });
              cache.put(fileIdentifier, updatedResponse);
              console.log("Cache updated for file:", fileIdentifier);
            }
          });
        });
      });
    }

    return () => {
      if (socket) {
        socket.off("receive-changes");
      }
    };
  }, [
    collaborativeEditingHandler,
    fileId,
    mimetype,
    profileData.email,
    socket,
  ]);

  useEffect(() => {
    const handleTabClose = async (event) => {
      const document =
        await editorRef.current.documentEditor.saveAsBlob("Docx");

      if (!document) {
        console.error("Failed to generate document blob.");
        return;
      }
      // Make the API call before the alert is shown
      const res = await handleTextExtraction(
        document,
        fileId,
        fileName,
        "red",
        profileData,
        true
      );

      // Show the confirmation dialog
      event.preventDefault();
      event.returnValue = "Are you sure you want to exit?"; // This triggers the alert for the user
    };

    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);

  // Save document to Firebase and cache
  const saveDocument = useCallback(
    async (showPopup, args) => {
      if (!isEditorReady) return;
      // if (args) {
      //   socket.emit("send-changes", args.operations);
      // }
      setSaving(true);
      const document =
        await editorRef.current.documentEditor.saveAsBlob("Docx");

      if (!document) {
        console.error("Failed to generate document blob.");
        return;
      }

      const file = document;
      context.setCurrFile(file);

      const fileRef = ref(storage, `files/${profileData.org}/${currVersion}`);
      const metadata = {
        customMetadata: {
          department_id: profileData.dept,
          org_id: profileData.org,
        },
      };

      const uploadTask = uploadBytesResumable(fileRef, file, metadata);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("File upload error:", error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          console.log("File available at:", downloadURL);
          const fileVersionId = generateFileVersionId(fileId);

          const token = await auth.currentUser.getIdToken();
          const fileIdentifier = `${fileId}-${token}`;

          await api.post(`file/update-file-version/${fileId}/`, {
            versionId: fileVersionId,
            idToken: token,
          });

          // Cache the file

          const cache = await caches.open(CACHE_NAME);
          const responseToCache = new Response(file, {
            headers: {
              "Content-Type": mimetype,
              "sw-cache-date": new Date().toISOString(),
              "x-file-version-id": fileVersionId,
            },
          });
          await cache.put(fileIdentifier, responseToCache);
          console.log("Cache updated for file:", fileIdentifier);
          setSaving(false);
          if (showPopup) toast.success("File saved and cached successfully.");
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isEditorReady,
      fileId,
      mimetype,
      profileData.dept,
      profileData.org,
      profileData.email,
      socket,
    ]
  );

  // Create a debounced version of the saveDocument function
  const debouncedSaveDocument = useCallback(debounce(saveDocument, 300), [
    saveDocument,
  ]);

  let toolItem = {
    prefixIcon: "e-save icon",
    tooltipText: "Save the Document",
    text: "Save",
    id: "save",
  };
  let items = [
    toolItem,
    "New",
    "Open",
    "Separator",
    "Undo",
    "Redo",
    "Separator",
    "Image",
    "Table",
    "Hyperlink",
    "Bookmark",
    "TableOfContents",
    "Separator",
    "Header",
    "Footer",
    "PageSetup",
    "PageNumber",
    "Break",
    "InsertFootnote",
    "InsertEndnote",
    "Separator",
    "Find",
    "Separator",
    "Comments",
    "TrackChanges",
    "Separator",
    "LocalClipboard",
    "RestrictEditing",
    "Separator",
    "FormFields",
    "UpdateFields",
    "ContentControl",
  ];
  if (userAccess && !userAccess?.includes("edit")) {
    items = [
      // toolItem,
      "Separator",
      "Undo",
      "Redo",
      "Separator",
      "Image",
      "Table",
      "Hyperlink",
      "Bookmark",
      "TableOfContents",
      "Separator",
      "Header",
      "Footer",
      "PageSetup",
      "PageNumber",
      "Break",
      "InsertFootnote",
      "InsertEndnote",
      "Separator",
      "Find",
      "Separator",
      "Comments",
      "TrackChanges",
      "Separator",
      "LocalClipboard",
      // "RestrictEditing",
      "Separator",
      "FormFields",
      "UpdateFields",
      "ContentControl",
    ];
  }

  const onToolbarClick = (args) => {
    switch (args.item.id) {
      case "save":
        saveDocument(true);
        break;
      default:
        break;
    }
  };

  function onCreated() {
    setIsEditorReady(true);

    setCollaborativeEditingHandler(
      editorRef.current.documentEditor.collaborativeEditingHandlerModule
    );

    console.log(collaborativeEditingHandler, editorRef.current.documentEditor);
  }

  const Editorhieght = window.innerHeight - 64;

  return (
    <>
      <DocumentEditorContainerComponent
        id="container"
        height={context.isMultiSelect ? "80vh" : Editorhieght}
        width="100%"
        serviceUrl="https://ej2services.syncfusion.com/production/web-services/api/documenteditor/"
        enableToolbar={true}
        ref={(scope) => {
          editorRef.current = scope;
        }}
        toolbarItems={items}
        toolbarClick={onToolbarClick}
        created={onCreated}
        contentChange={(args) => {
          if (args) {
            socket.emit("send-changes", args.operations);
          }
          debouncedSaveDocument(false, args);
        }}
        currentUser={profileData.email}
      >
        <Inject services={[Toolbar, WordExport]} />
      </DocumentEditorContainerComponent>
    </>
  );
}
