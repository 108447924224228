import React from "react";
import Plyr from "plyr-react";
import "plyr/dist/plyr.css";

const VideoPlayer = ({ preUrl }) => {
  const videoSource = {
    type: "video",
    sources: [
      {
        src: preUrl,
        type: "video/mp4",
      },
    ],
  };

  return (
    <div
      style={{
        height: "calc(100vh - 64px)",
        display: "flex",
        // justifyContent: "center",
        // alignItems: "center",
      }}
    >
      <Plyr type="video" source={videoSource} controls={true} />
    </div>
  );
};

export default VideoPlayer;
